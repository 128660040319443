import React from 'react'

import MultiSelectFilter from './MultiSelectFilter'

const stateOptions = [
  { value: undefined, label: 'Alle' },
  { value: 'saxo_house_transfer_not_started', label: 'Not started' },
  { value: 'saxo_house_transfer_notification_received', label: 'Notification received' },
  { value: 'saxo_house_transfer_documents_signed', label: 'Documents signed' },
  {
    value: 'saxo_house_transfer_signed_documents_uploaded_to_saxo',
    label: 'Signed documents uploaded to SAXO',
  },
  { value: 'saxo_house_transfer_pending', label: 'Pending' },
  { value: 'saxo_house_transfer_completed', label: 'Completed' },
]

const StateFilter = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ options: ({ value: undefined; label: strin... Remove this comment to see the full error message
  <MultiSelectFilter options={stateOptions} {...otherProps} />
)

export default StateFilter
