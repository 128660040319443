import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration/config'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { humanizedBoolean, humanizedBooleanOrNotRelevant, humanizedDate } from '../../../../utils'
import Filter from '../Filter'
import Selection from '../Selection'

import UpdateButton from './UpdateButton'

const backendDomain = getConfig('domains.backend')

export interface TradingCommentProps {
  cell: { value: string }
}

const TradingComment = ({ cell: { value } }: TradingCommentProps) => {
  if (!value) return value

  return (
    // @ts-expect-error TS(2741) FIXME: Property 'id' is missing in type '{ children: stri... Remove this comment to see the full error message
    <OverlayTrigger placement="left" overlay={<Tooltip>{value}</Tooltip>}>
      <Text>{value}</Text>
    </OverlayTrigger>
  )
}

export interface CellProps {
  cell: { value: string }
  row: { original: { userId: number; portfolioId: number } }
}

const EmailLink = ({
  cell: { value },
  row: {
    original: { userId },
  },
}: CellProps) => {
  const handleClick = (event: React.MouseEvent) => event.stopPropagation()

  return (
    <Text
      as="a"
      onClick={handleClick}
      href={`${backendDomain}/limited_admin/users/${userId}`}
      target="_blank"
    >
      {value}
    </Text>
  )
}

const AccountLink = ({
  cell: { value },
  row: {
    original: { portfolioId },
  },
}: CellProps) => {
  const handleClick = (event: React.MouseEvent) => event.stopPropagation()

  return (
    <Text
      as="a"
      onClick={handleClick}
      href={`${backendDomain}/limited_admin/portfolios/${portfolioId}/edit`}
      target="_blank"
    >
      {value}
    </Text>
  )
}

const TradeLink = ({
  row: {
    original: { portfolioId },
  },
}: Omit<CellProps, 'cell'>) => {
  const handleClick = (event: React.MouseEvent) => event.stopPropagation()

  return (
    <Text
      as="a"
      onClick={handleClick}
      href={`/portfolios/trade?portfolio_ids=${portfolioId}`}
      target="_blank"
    >
      Handel
    </Text>
  )
}

const columns = [
  {
    id: 'selection',
    Header: Selection.Header,
    Cell: Selection.Cell,
    isHideable: false,
    width: 'auto',
  },
  {
    id: 'trade',
    Header: '',
    Cell: TradeLink,
    disableSortBy: true,
  },
  {
    id: 'saxo_house',
    Header: 'Saxo House',
    accessor: 'saxoHouse',
    Cell: ({ cell: { value } }: { cell: { value: 'interactive_broker' | 'money_manager' } }) =>
      ({
        interactive_broker: 'IB',
        money_manager: 'MM',
      }[value]),
    Filter: Filter.SaxoHouse,
    filter: 'equals',
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: EmailLink,
    disableSortBy: true,
  },
  {
    Header: 'Konto ID',
    accessor: 'saxoBankAccountId',
    Cell: AccountLink,
    disableSortBy: true,
  },
  {
    id: 'orderHandlingAccepted',
    Header: 'Handel godkendt',
    accessor: 'orderHandlingAccepted',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
      humanizedBooleanOrNotRelevant(value),
    Filter: Filter.Boolean,
    filter: 'equals',
    disableSortBy: true,
  },
  {
    Header: 'Kommentar',
    accessor: 'tradingInfoComment',
    Filter: Filter.Comment,
    disableSortBy: true,
    Cell: TradingComment,
  },
  {
    accessor: 'hasTradingInfoComment',
    disableSortBy: true,
    Cell: () => null,
    isVisible: false,
    isHideable: false,
  },
  {
    Header: 'Kontanter',
    accessor: 'cashBalance',
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: number } }) => numberToCurrency(value),
  },
  {
    Header: 'Sidste indbetaling / udbytte',
    accessor: 'lastDeposit',
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: number } }) => numberToCurrency(value),
  },
  {
    Header: 'Anmodet om risikoprofil ændring',
    accessor: 'readyForRiskProfileChange',
    Filter: Filter.Boolean,
    disableSortBy: true,
    Cell: ({ cell: { value } }: { cell: { value: boolean } }) => humanizedBoolean(value),
  },
  {
    Header: 'Kontanter på tværs af konti',
    accessor: 'userFreeAssetsPortfoliosTotalCashBalance',
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: number } }) => numberToCurrency(value),
  },
  {
    Header: 'Klar til rebalancering',
    accessor: 'rebalanceable',
    Filter: Filter.Boolean,
    Cell: ({ cell: { value } }: { cell: { value: boolean } }) =>
      humanizedBooleanOrNotRelevant(value),
  },
  {
    Header: 'Rebalancering accepteret',
    accessor: 'rebalancingAcceptedAt',
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string | null } }) => humanizedDate(value),
  },
  {
    id: 'userHasFreeAssetsPortfolioInOverdraft',
    Header: 'Konto/konti i overtræk',
    accessor: 'userHasFreeAssetsPortfolioInOverdraft',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
      humanizedBooleanOrNotRelevant(value),
    Filter: Filter.Boolean,
    filter: 'equals',
    disableSortBy: true,
  },
  {
    Header: 'Sidste indbetalingsdato / udbyttedato',
    accessor: 'lastDepositDate',
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string | null } }) => humanizedDate(value),
  },
  {
    Header: 'Depottype',
    accessor: 'depotType',
    Filter: Filter.DepotType,
    filter: 'includes',
    disableSortBy: true,
  },
  {
    Header: 'Produkter',
    accessor: 'products',
    Filter: Filter.Product,
    filter: 'includes',
    disableSortBy: true,
    Cell: ({ cell: { value } }: { cell: { value?: string[] } }) => value?.join(', '),
  },
  {
    id: 'invested',
    Header: 'Investeret',
    accessor: 'invested',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
      humanizedBooleanOrNotRelevant(value),
    Filter: Filter.Boolean,
    filter: 'equals',
    disableSortBy: true,
  },
  {
    id: 'correctDocumentsSignedForSimple',
    Header: 'Simpel portefølje dokumenter',
    accessor: 'correctDocumentsSignedForSimple',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
      humanizedBooleanOrNotRelevant(value),
    Filter: Filter.SimpleDocuments,
    filter: 'equals',
    disableSortBy: true,
  },
  {
    Header: 'Risikoprofil',
    accessor: 'riskScore',
    Filter: Filter.RiskScore,
    filter: 'equals',
  },
  {
    id: 'esg',
    Header: 'ESG',
    accessor: 'esg',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
      humanizedBooleanOrNotRelevant(value),
    Filter: Filter.Boolean,
    filter: 'equals',
    disableSortBy: true,
  },
  {
    id: 'suitable',
    Header: 'Egnet',
    accessor: 'suitable',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
      humanizedBooleanOrNotRelevant(value),
    Filter: Filter.Boolean,
    filter: 'equals',
    disableSortBy: true,
  },
  {
    id: 'pendingOrders',
    Header: 'Under handel',
    accessor: 'pendingOrders',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
      humanizedBooleanOrNotRelevant(value),
    Filter: Filter.Boolean,
    filter: 'equals',
    disableSortBy: true,
  },
  {
    id: 'portfolioId',
    Header: 'Opdater',
    accessor: 'portfolioId',
    disableFilters: true,
    Cell: UpdateButton,
  },
  {
    id: 'investable',
    Header: 'Klar til investering',
    accessor: 'investable',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
      humanizedBooleanOrNotRelevant(value),
    Filter: Filter.Boolean,
    filter: 'equals',
    disableSortBy: true,
  },
]

export default columns
