import BaffleButton from '@nord/ui/src/components/BaffleButton'
import useApi from '@nord/ui/src/hooks/useApi'
import React from 'react'

import { useUsersContext } from '../UsersContext'

export interface Props {
  row: any // TODO: PropTypes.shape()
}

const UpdateButton = ({
  row: {
    original: { id, saxoHouseTransferDocumentsSignedAt, saxoHouseTransferCompletedAt },
  },
}: Props) => {
  const { getUsers } = useUsersContext()

  const type: string = saxoHouseTransferDocumentsSignedAt ? "pending" : "manually_sent"

  const updateUser = useApi(`/limited_admin/users/saxo_house_transfers/${id}`, {
    method: 'PATCH',
    withCredentials: true,
    errorHandling: { ignore: { client: true } },
  })

  if (!type || saxoHouseTransferCompletedAt) return null

  const handleUpdate = async (event: any) => {
    event.stopPropagation()

    const { success } = await updateUser({ state: type })

    if (success) await getUsers()
  }

  return (
    <BaffleButton
      onClick={handleUpdate}
      variant="link"
      className="btn-anchor"
      title="Opdater"
    >
      Update to {type}
    </BaffleButton>
  )
}

export default UpdateButton
