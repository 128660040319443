import React from 'react'

import SelectFilter from './SelectFilter'

const depotTypeOptions = [
  { value: undefined, label: 'Alle' },
  { value: 'interactive_broker', label: 'Interactive Broker' },
  { value: 'money_manager', label: 'Money Manager' },
]

const SaxoHouseFilter = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ options: ({ value: undefined; label: strin... Remove this comment to see the full error message
  <SelectFilter options={depotTypeOptions} {...otherProps} />
)

export default SaxoHouseFilter
