import transform from 'lodash/transform'
import { useState, useEffect, useCallback } from 'react'

const useFormValidation = (schema: any, values: any, touched: any) => {
  const [isValid, setIsValid] = useState(false)
  const [errors, setErrors] = useState({})

  const validate = useCallback(
    async (newValues: any) => {
      try {
        await schema.validate(newValues, {
          abortEarly: false,
        })
        setIsValid(true)
        setErrors({})

        return true
      } catch (error) {
        // @ts-expect-error TS(2339) FIXME: Property 'inner' does not exist on type 'unknown'.
        const { inner: innerErrors } = error
        const formattedErrors = transform(
          innerErrors,
          (result, innerError) => {
            const { path, message } = innerError
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            result[path] = result[path] || message
          },
          {},
        )

        setErrors(formattedErrors)
        setIsValid(false)

        return false
      }
    },
    [schema],
  )

  useEffect(() => {
    if (touched && Object.values(touched).every((value) => !value)) return

    validate(values)
  }, [touched, validate, values])

  return { isValid, errors, validate }
}

export default useFormValidation
